<template>
  <div>
    <div class="text-h6">{{ data.campaign_name }}</div>
    <div
      v-if="data.campaign_id == 0"
      class="items-center row rounded-borders text-weight-bold q-py-md q-px-sm text-white bg-purple-5"
    >
      <q-icon name="warning" class="col-1" style="font-size: 28px" />
      <p class="text-sm col-11">
        Atenção! Ao concluir essa configuração, ela será aplicada apenas às campanhas que NÃO têm postback configurado.
        Caso você tenha campanhas configuradas com postback único, ele será mantido.
      </p>
    </div>
    <q-separator spaced />
    <q-input v-model="params.url" outlined type="url" dense label="URL para direcionamento de volta" />
    <q-separator spaced="lg" />
    <q-card flat bordered>
      <q-toolbar class="bg-primary text-white">
        <q-toolbar-title class="text-body1"> Parâmetros adicionais </q-toolbar-title>
        <q-btn color="positive" icon="mdi-plus" unelevated @click="addParameters">
          <q-tooltip> Adicionar parametro</q-tooltip>
        </q-btn>
      </q-toolbar>
      <q-card-section class="row q-col-gutter-md">
        <div class="col-6">Parâmetros</div>
        <div class="col-6">Valor</div>
      </q-card-section>

      <q-card-section class="row q-col-gutter-md q-pt-xs" v-for="(item, index) in additionalParameters" :key="index">
        <div class="col-6">
          <q-input v-model="item.param_name" type="text" dense label="Nome do parâmetro" outlined>
            <!-- <template v-slot:after>
              <q-btn unelevated color="negative" icon="mdi-close" @click="removeParameters(index)" />
            </template> -->
          </q-input>
        </div>
        <div class="col-6" style="display: flex; align-items: center">
          <q-select
            v-model="item.param"
            outlined
            dense
            :options="paramsAll"
            option-label="param_indice_name"
            option-value="id"
            label="Variável"
            style="flex: 1; margin-right: 10px"
          />
          <q-btn unelevated color="negative" icon="mdi-close" @click="removeParameters(index)" />
        </div>
      </q-card-section>
    </q-card>
    <q-separator spaced="lg" />
    <q-card flat bordered>
      <q-toolbar class="bg-primary text-white">
        <q-toolbar-title class="text-body1"> Parâmetros Personalizados </q-toolbar-title>
        <q-btn color="positive" icon="mdi-plus" unelevated @click="addCustomParameters">
          <q-tooltip> Adicionar parametro</q-tooltip>
        </q-btn>
      </q-toolbar>
      <q-card-section class="row q-col-gutter-md">
        <div class="col-6">Parâmetros</div>
        <div class="col-6">Valor</div>
      </q-card-section>

      <q-card-section class="row q-col-gutter-md q-pt-xs" v-for="(item, index) in customParameters" :key="index">
        <div class="col-6">
          <q-input v-model="item.param_name" outlined dense type="text" label="Nome do parâmetro" />
        </div>
        <div class="col-6">
          <q-input v-model="item.param_custom_value" type="text" dense label="Variável" outlined>
            <template v-slot:after>
              <q-btn unelevated color="negative" icon="mdi-close" @click="removeCustomParameters(index)" />
            </template>
          </q-input>
        </div>
      </q-card-section>
    </q-card>
    <q-separator spaced="lg" />
    <q-card flat bordered>
      <q-toolbar class="bg-primary text-white">
        <q-toolbar-title class="text-body1"> Autenticação Básica </q-toolbar-title>
        <q-toggle color="positive" unelevated v-model="basicAuth"> </q-toggle>
      </q-toolbar>

      <q-card-section
        v-show="basicAuth"
        class="row q-col-gutter-md"
        transition-show="slide-up"
        transition-hide="slide-down"
      >
        <div class="col-6">
          <q-input v-model="auth.login" outlined dense type="text" label="Login de autenticação" />
        </div>
        <div class="col-6">
          <q-input v-model="auth.password" outlined dense label="Senha" :type="isPwd ? 'password' : 'text'">
            <template v-slot:append>
              <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwd = !isPwd" />
            </template>
          </q-input>
        </div>
      </q-card-section>
    </q-card>

    <q-separator spaced="lg" />
    <span class="text-body2">URL final para chamar de volta</span>
    <q-banner inline-actions class="bg-purple text-white" rounded>
      {{ finalURL }}
      <template v-slot:action>
        <q-btn no-caps unelevated outlined icon="mdi-content-copy" @click="copyData(finalURL)">
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Copiar URL </q-tooltip>
        </q-btn>
      </template>
    </q-banner>
  </div>
</template>

<script>
import { copyToClipboard } from 'quasar';

export default {
  name: 'PostBackParamsAffiliate',
  data() {
    return {
      isPwd: true,
      auth: {
        password: '',
        login: ''
      },
      paramsAll: [],
      additionalParameters: [],
      customParameters: [],
      basicAuth: false
    };
  },
  props: {
    data: Object,
    params: {
      type: Object,
      default: () => ({
        param_name: null,
        param_indice_id: null,
        param_indice_name: null
      })
    },
    channelId: [Number, String]
  },
  computed: {
    finalURL() {
      if (this.params.url) {
        let queryParams = '';

        if (this.additionalParameters.length > 0) {
          this.additionalParameters.forEach((element, index) => {
            if (element.param) {
              if (element.param_name && element.param.param_indice_name) {
                queryParams += `${element.param_name}={${this.convertToSnakeCase(element.param.param_indice_name)}}`;
                if (index !== this.additionalParameters.length - 1) queryParams += '&';
              }
            }
          });
        }

        if (this.customParameters.length > 0) {
          if (queryParams !== '') queryParams += '&';
          this.customParameters.forEach((element, index) => {
            if (element.param_name && element.param_custom_value) {
              queryParams += element.param_name + '=' + element.param_custom_value;
              if (index !== this.customParameters.length - 1) queryParams += '&';
            }
          });
        }

        return this.params.url + '?' + queryParams;
      }
      return '';
    }
  },
  methods: {
    addCustomParameters() {
      this.customParameters.push({
        param: null,
        value: null
      });
    },
    copyData(value) {
      copyToClipboard(value)
        .then(() => {
          this.successNotify('URL Copiada!');
        })
        .catch(() => {
          this.errorNotify('Erro ao copiar URL');
        });
    },
    convertToSnakeCase(string) {
      return string
        .replace(/\W+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map((word) => word.toLowerCase())
        .join('_');
    },
    addParameters() {
      this.additionalParameters.push({
        param: null,
        value: null
      });
    },
    removeParameters(index) {
      this.additionalParameters.splice(index, 1);
    },
    removeCustomParameters(index) {
      this.customParameters.splice(index, 1);
    },
    async getParameters() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          '/redirect?app=conversorphp&path=%2Fapi%2Fpostback%2Fparams%2Findices'
        );
        if (status === 200) this.paramsAll = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    base64ToUtf8(string) {
      return decodeURIComponent(escape(window.atob(string)));
    },

    setParamValues() {
      if (this.params.params) {
        this.additionalParameters = this.params.params.map((item) => ({
          param: {
            id: item.param_indice_id,
            param_indice_name: item.param_indice_name
          },
          param_name: item.param_name
        }));
      }

      if (this.params.custom_params !== undefined) {
        this.customParameters = this.params.custom_params;
      } else {
        this.customParameters = [];
      }
      if (this.params.auth !== undefined) {
        this.auth = {
          ...this.params.auth,
          password: this.base64ToUtf8(this.params.auth.password)
        };

        this.basicAuth = true;
      } else {
        this.auth = {};
        this.basicAuth = false;
      }
    }
  },
  created() {
    this.getParameters();
    this.setParamValues();
  },
  watch: {
    params() {
      this.setParamValues();
    },

    auth: {
      handler(val) {
        this.$emit('updateAuth', val, this.basicAuth);
      },
      deep: true
    },

    basicAuth() {
      this.$emit('updateAuth', this.auth, this.basicAuth);
    }
  }
};
</script>

<style></style>

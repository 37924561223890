<template>
  <q-page padding>
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Postback</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/" />
        <q-breadcrumbs-el label="Postback" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <post-back-affiliate v-if="user.profile == 'afiliado'" />
    <post-back-manager v-else />
  </q-page>
</template>

<script>
import PostBackManager from '../../components/affiliate/PostBackManager.vue';
import PostBackAffiliate from '../../components/affiliate/PostBackAffiliate.vue';
export default {
  components: { PostBackManager, PostBackAffiliate },
  name: 'PagePostBack'
};
</script>

<style></style>

<template>
  <div class="q-gutter-md">
    <q-card flat bordered>
      <q-toolbar class="bg-grey-3 text-dark">
        <q-toolbar-title class="text-body1"> Etapa 1: Selecionar afiliado </q-toolbar-title>
      </q-toolbar>
      <q-card-section>
        <q-select
          v-model="affiliate"
          outlined
          :options="affiliates"
          use-input
          @filter="filterAffiliate"
          input-debounce="2"
          label="Afiliado"
          option-label="name"
          option-value="id"
          clearable
          clear-icon="mdi-close"
        />
      </q-card-section>
    </q-card>
    <q-card flat bordered v-if="affiliate">
      <q-toolbar class="bg-grey-3 text-dark">
        <q-toolbar-title class="text-body1"> Etapa 2: Selecione o canal de divulgação e a campanha </q-toolbar-title>
      </q-toolbar>
      <q-card-section class="row q-col-gutter-md">
        <div class="col-6">
          <q-select
            v-model="channel"
            outlined
            clearable
            clear-icon="mdi-close"
            :options="channels"
            label="Canais"
            option-label="name"
            option-value="id"
          />
        </div>
        <div class="col-6">
          <q-select
            ref="qSelectCampaign"
            v-model="campaign"
            outlined
            :options="campaigns"
            clear-icon="mdi-close"
            label="Campanhas"
            option-label="campaign_name"
            option-value="campaign_id"
            multiple
            use-chips
          />
        </div>
      </q-card-section>
    </q-card>
    <q-card flat bordered v-if="campaign.length > 0 && affiliate">
      <q-toolbar class="bg-grey-3 text-dark">
        <q-toolbar-title class="text-body1"> Etapa 3: Url e parâmetros </q-toolbar-title>
      </q-toolbar>
      <q-card-section>
        <q-tabs style="z-index: 2" v-model="tab" indicator-color="primary" align="left">
          <q-tab
            :name="item.campaign_name"
            :label="item.campaign_name"
            no-caps
            v-for="(item, index) in campaign"
            :key="index"
          />
        </q-tabs>
        <q-tab-panels v-model="tab" animated swipeable vertical>
          <q-tab-panel :name="item.campaign_name" v-for="(item, index) in campaign" :key="index">
            <post-back-params
              :ref="item.campaign_id"
              :data="item"
              @updateAuth="
                (data, enable) => {
                  auth = data;
                  enableBasicAuth = enable;
                }
              "
              :params="params.find((ele) => ele.campaign_id == (item.campaign_id == 0 ? null : item.campaign_id))"
              :channelId="channel.id"
            />
          </q-tab-panel>
        </q-tab-panels>
      </q-card-section>
      <q-card-actions align="right" class="q-px-md q-pb-md q-gutter-md">
        <q-btn no-caps unelevated class="q-px-md" color="positive" label="Salvar" @click="saveParams()" />
        <q-btn
          v-if="campaign.length > 1 && tab != 'Todas as campanhas'"
          no-caps
          class="q-px-md"
          unelevated
          color="positive"
          label="Salvar para todas as campanhas selecionadas"
          @click="saveAllParams()"
        />
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import PostBackParams from './PostBackParams.vue';
import ConsersorPHPService from '../../services/ConversorPhpService';

export default {
  components: { PostBackParams },
  mixins: [ConsersorPHPService],
  name: 'PostBackManager',
  data() {
    return {
      tab: null,
      affiliate: null,
      affiliates: [],
      affiliatesList: [],
      channel: null,
      channels: [],
      campaign: [],
      campaigns: [],
      params: [],
      auth: {},
      enableBasicAuth: false,
      channelsFiltered: [],
      campaignsFiltered: []
    };
  },
  methods: {
    saveAllParams() {
      this.showDialog(
        'Salvar Parametros para todas as campanhas',
        'Tem certeza que deseja salvar esses parametros para todas as campanhas abertas? ',
        () => {
          this.onOk();
        },
        () => {
          this.onCancel();
        }
      );
    },
    onOk() {
      this.saveParams('saveAll');
    },
    onCancel() {
      this.infoNotify('Ação cancelada');
    },

    async saveParams(saveAllOpen = null) {
      let selectedCampaign;
      const saveAllChannelCampaigns = this.tab == 'Todas as campanhas';
      if (saveAllChannelCampaigns) {
        selectedCampaign = [0];
      } else if (!saveAllOpen) {
        selectedCampaign = this.campaign.find((ele) => ele.campaign_name == this.tab);
        selectedCampaign = [selectedCampaign.campaign_id];
      } else {
        selectedCampaign = this.campaign.map((ele) => ele.campaign_id);
      }

      let selectedIdTab = this.campaign.find((ele) => ele.campaign_name == this.tab),
        paramsRefs = this.$refs[selectedIdTab.campaign_id][0],
        addicionalParams = paramsRefs.additionalParameters.reduce(
          (obj, item) => Object.assign(obj, { [item.param_name]: item.param.id }),
          {}
        ),
        customParameters = paramsRefs.customParameters.map((el) => ({
          param: el.param_name,
          value: el.param_custom_value
        }));

      this.onLoading(true);
      try {
        let authParams =
          this.enableBasicAuth === true &&
          typeof this.auth.login !== 'undefined' &&
          this.auth.login !== '' &&
          typeof this.auth.password !== 'undefined' &&
          this.auth.password !== ''
            ? {
                login: this.auth.login,
                password: this.auth.password
              }
            : null;

        const data = {
          affiliate_id: this.affiliate.id,
          site_id: this.channel.id,
          url: paramsRefs.params.url,
          auth: authParams,
          token: this.affiliate.token,
          campaign_id: selectedCampaign,
          custom_params: customParameters,
          params: addicionalParams
        };

        const { status } = await this.savePostback(data);

        if (status === 200) {
          this.successNotify('Parametros adicionados com sucesso!');
          this.getParameters();
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    async getAffiliates() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=affiliate&path=/api/get/affiliate/filter');
        if (status === 200)
          this.affiliates = data.map((item) => ({
            ...item,
            name: `${item.name} - ${item.business_name} (id:${item.id})`
          }));
        this.affiliatesList = this.affiliates;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    async getChannels() {
      this.onLoading(true);
      this.channel = { id: null, name: null };
      this.campaign = [];
      this.campaigns = [];
      try {
        const { data, status } = await this.$http.get(
          `/redirect?app=affiliate&path=/api/get/affiliate/site_active/filter/${this.affiliate.id}`
        );
        if (status === 200) this.channels = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    async getCampaigns() {
      this.onLoading(true);
      this.campaign = [];
      this.campaigns = [];
      try {
        const { data, status } = await this.$http.get(
          `/redirect?app=advertiser&path=/api/get/campaign/approved/filter/site/${this.channel.id}`
        );
        if (status === 200) this.campaigns = data.map((item) => ({ campaign_id: item.id, campaign_name: item.name }));

        if (this.campaigns.length > 0) this.campaigns.unshift({ campaign_id: 0, campaign_name: 'Todas as campanhas' });
        /* if (status === 200) this.campaigns = data;
                console.log(this.campaigns); */
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    async getParameters() {
      this.onLoading(true);
      try {
        const campaign_ids = this.campaign
          // .filter((item) => item.campaign_id != 0)
          .map((item) => item.campaign_id)
          .join(',');
        const queryString = `campaignId=${campaign_ids}%26siteId=${this.channel.id}`;
        const { data, status } = await this.$http.get(
          `/redirect?app=CONVERSORPHP&path=/api/postback/params/list%3F${queryString}`
        );
        if (status === 200) this.params = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    filterAffiliate(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.affiliates = this.affiliatesList;
        this.affiliates = this.affiliatesList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
      return;
    }
  },
  watch: {
    affiliate() {
      if (this.affiliate?.id) this.getChannels();
    },
    channel() {
      if (this.channel?.id) this.getCampaigns();
    },
    campaign() {
      if (this.campaign.length > 0 && this.tab == null) this.tab = this.campaign[0].campaign_name;
      if (this.campaign.find((camp) => camp.campaign_id == 0)) {
        this.$refs.qSelectCampaign.hidePopup();
      }
      this.getParameters();
    }
  },
  created() {
    this.getAffiliates();
  }
};
</script>

<style></style>
